import { css } from "@emotion/react";

import variables from "../styles/variables";

export const iconHoverPath = (
  initialColor = variables.colorSecondaryGray,
  hoverColor = variables.colorPrimaryGray
) => css`
  path,
  circle {
    transition: fill ${variables.transitionSpeed} ease;
    fill: ${initialColor};
  }

  &:hover {
    path,
    circle {
      fill: ${hoverColor};
    }
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
