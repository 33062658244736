import React, { ReactElement, ReactNode, useEffect } from "react";
import NextHead from "next/head";
import getConfig from "next/config";
import { useRouter } from "next/router";

import { gtagPageview } from "../../helpers/gtag";

const { publicRuntimeConfig } = getConfig();
const { appName, appTagline, gaTrackingId } = publicRuntimeConfig;

type Input = {
  children?: ReactNode;
  title?: string;
  description?: string;
  openGraph?: { property: string; content: string }[];
  twitterCard?: { name: string; content: string }[];
  noIndex?: boolean;
  canonical?: string;
};

const Head = ({
  children,
  title = "",
  description = "",
  openGraph,
  twitterCard,
  noIndex = false,
  canonical,
}: Input): ReactElement => {
  const router = useRouter();

  const renderTitle = title
    ? `${title} | ${appName}`
    : `${appName} - ${appTagline}`;

  useEffect(() => {
    if (gaTrackingId) {
      gtagPageview({ path: router.pathname, title: renderTitle });
    }
  }, []);

  return (
    <NextHead>
      <title>{renderTitle}</title>
      {noIndex && <meta name="robots" content="noindex,follow" />}
      {canonical && <link rel="canonical" href={canonical} />}
      {description && <meta name="description" content={description} />}
      {openGraph?.map((graph) => (
        <meta
          key={graph.property}
          property={graph.property}
          content={graph.content}
        />
      ))}
      {twitterCard?.map((card) => (
        <meta key={card.name} name={card.name} content={card.content} />
      ))}
      {children}
    </NextHead>
  );
};

export default Head;
