import React, { ReactElement } from "react";
import { css } from "@emotion/react";

const smallBreakPoint = pxToRem(817);
const mediumBreakPoint = pxToRem(1024);

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import { h4Bold, h4Regular } from "../../styles/typography";
import AccentButton from "../common/accent-button";

const wrapperStyle = css`
  display: grid;
  background: ${variables.colorWhite};
`;

const content = css`
  z-index: 1;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: ${pxToRem(110)} ${variables.spaceBase};

  @media (min-width: ${smallBreakPoint}) {
    max-width: ${pxToRem(1030)};
    width: 100%;
    margin: 0 auto;
  }
`;

const subTitle = css`
  max-width: ${pxToRem(640)};
  margin-bottom: ${variables.spaceMedium};
  font-size: ${pxToRem(32)};
  font-weight: ${variables.fontWeightLight};
  text-align: center;
  color: ${variables.colorMainBlue};

  @media (min-width: ${mediumBreakPoint}) {
    font-size: ${pxToRem(42)};
  }
`;

const explanationText = css`
  ${h4Regular};
  margin-bottom: ${pxToRem(40)};
  line-height: 1.6;
  color: ${variables.colorSecondaryGray};
  max-width: ${pxToRem(740)};
  text-align: center;
`;

const heroButtonStyle = css`
  ${h4Bold};
  display: inline-block;
  font-family: ${variables.marketingHeaderFontFamily};
  font-weight: ${variables.fontWeightSemiBold};
`;

const formEmbed = css`
  position: relative;

  iframe {
    width: 85vw;
  }
`;

type Input = {
  isFormOpen: boolean;
  onOpenForm: () => void;
};

const ReadyToInnovate = ({ isFormOpen, onOpenForm }: Input): ReactElement => {
  return (
    <div css={wrapperStyle}>
      <div css={content}>
        <h3 css={subTitle}>Ready to innovate?</h3>
        <p css={explanationText}>
          Get in touch to learn more about using Invent Value within your
          organization.
        </p>
        {!isFormOpen && (
          <AccentButton style={heroButtonStyle} onClick={onOpenForm}>
            Contact Us
          </AccentButton>
        )}
        <div id="typeform" css={formEmbed}>
          {isFormOpen && (
            <div id="LSgq6S" style={{ width: "100%", height: "500px" }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadyToInnovate;
