import React, { ReactElement, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";

type Input = {
  date?: Date;
  dateString?: string;
  autoUpdateInSeconds?: number;
};

const getRelativeTime = (dateString, date) => {
  let dateTime: DateTime;
  if (dateString) {
    dateTime = DateTime.fromISO(dateString);
  } else if (date) {
    dateTime = DateTime.fromJSDate(date);
  }

  if (!dateTime) {
    return null;
  }

  return dateTime.toRelative();
};

const DisplayRelativeTime = ({
  date,
  dateString,
  autoUpdateInSeconds,
}: Input): ReactElement => {
  const ref = useRef(null);
  const [relativeTime, setRelativeTime] = useState(
    getRelativeTime(dateString, date)
  );

  useEffect(() => {
    setRelativeTime(getRelativeTime(dateString, date));

    if (autoUpdateInSeconds !== undefined) {
      ref.current = setInterval(() => {
        setRelativeTime(getRelativeTime(dateString, date));
      }, autoUpdateInSeconds * 1000);
    }
    return () => {
      clearInterval(ref.current);
    };
  }, [dateString, date]);

  return <>{relativeTime}</>;
};

export default DisplayRelativeTime;
