import React, { ReactElement } from "react";
import Modal from "react-modal";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";

import pxToRem from "../../styles/px-to-rem";
import CircleCloseButton from "../common/circle-close-button";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(45, 57, 65, 0.65)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    width: pxToRem(520),
    height: pxToRem(560),
    overflow: "visible",
    border: "0",
    background: "transparent",
  },
};

const closeButton = css`
  position: absolute;
  top: 0;
  right: -${pxToRem(60)};
`;

type Input = {
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  onRequestClose: () => void;
};

const HubspotModal = ({
  isOpen = false,
  onRequestClose,
  shouldCloseOnOverlayClick = true,
}: Input): ReactElement => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </Helmet>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Watch video"
        style={customStyles}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <CircleCloseButton style={closeButton} onClick={onRequestClose} />

        <div>
          <div
            className="meetings-iframe-container"
            data-src="https://app.hubspot.com/meetings/johnnydance/meeting-with-johnny-from-fresh-consulting?embed=true"
          />
        </div>
      </Modal>
    </>
  );
};

export default HubspotModal;
