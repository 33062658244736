import React, { ReactElement } from "react";
import { css } from "@emotion/react";

import pxToRem from "../../styles/px-to-rem";

const wrapperStyle = css`
  display: grid;
  margin: ${pxToRem(10)} 0;
  grid-gap: ${pxToRem(10)};

  @media (min-width: ${pxToRem(817)}) {
    grid-template-columns: 1400fr 1188fr 1536fr 1524fr;
  }
`;

const galleryImage = css`
  max-width: 100%;

  img {
    width: 100%;
  }
`;

const PhotoGallerySection = (): ReactElement => {
  return (
    <div css={wrapperStyle}>
      <picture css={galleryImage}>
        <source
          srcSet="https://storage.googleapis.com/iv-prod-static/home-gallery-1.webp"
          type="image/webp"
        />
        <source
          srcSet="https://storage.googleapis.com/iv-prod-static/home-gallery-1.jpg"
          type="image/jpg"
        />
        <img
          src="https://storage.googleapis.com/iv-prod-static/home-gallery-1.jpg"
          alt="Hardware"
        />
      </picture>
      <picture css={galleryImage}>
        <source
          srcSet="https://storage.googleapis.com/iv-prod-static/home-gallery-2.webp"
          type="image/webp"
        />
        <source
          srcSet="https://storage.googleapis.com/iv-prod-static/home-gallery-2.jpg"
          type="image/jpg"
        />
        <img
          src="https://storage.googleapis.com/iv-prod-static/home-gallery-2.jpg"
          alt="Two people looking at a monitor"
        />
      </picture>
      <picture css={galleryImage}>
        <source
          srcSet="https://storage.googleapis.com/iv-prod-static/home-gallery-3.webp"
          type="image/webp"
        />
        <source
          srcSet="https://storage.googleapis.com/iv-prod-static/home-gallery-3.jpg"
          type="image/jpg"
        />
        <img
          src="https://storage.googleapis.com/iv-prod-static/home-gallery-3.jpg"
          alt="Group of people looking at blueprint designs"
        />
      </picture>
      <picture css={galleryImage}>
        <source
          srcSet="https://storage.googleapis.com/iv-prod-static/home-gallery-4.webp"
          type="image/webp"
        />
        <source
          srcSet="https://storage.googleapis.com/iv-prod-static/home-gallery-4.jpg"
          type="image/jpg"
        />
        <img
          src="https://storage.googleapis.com/iv-prod-static/home-gallery-4.jpg"
          alt="Two people brainstorming"
        />
      </picture>
    </div>
  );
};

export default PhotoGallerySection;
