import i18n from "../helpers/i18n";

import IVWallpaper from "../public/static/invent-value-wallpaper-16-9.jpg";

export const AVATAR_DEFAULT_URL = "/static/avatar.svg";
export const AVATAR_DEFAULT_ALT_URL = "/static/avatar-alt.svg";
export const AVATAR_ANONYMOUS_URL = "/static/anonymous.svg";
export const AVATAR_GROUP_URL = "/static/avatar-group.svg";
export const AVATAR_VOC_URL = "/static/voice-of-customer.svg";
export const AVATAR_HUMAN1_URL = "/static/avatars/avatar1.png";
export const AVATAR_HUMAN2_URL = "/static/avatars/avatar2.png";
export const AVATAR_HUMAN3_URL = "/static/avatars/avatar3.png";
export const AVATAR_HUMAN4_URL = "/static/avatars/avatar4.png";
export const AVATAR_MONOGRAM_URL = "/static/monogram.svg";
export const AVATAR_BUSINESS_PLACEHOLDER_URL =
  "/static/business-placeholder.svg";
export const AVATAR_ROBOT_URL = "/static/icons/avatar-robot.svg";

export const DEFAULT_LOGO_URL = "/static/logo-inverted.svg";

export const AVATAR_SIZE_MOBILE = 48;
export const AVATAR_SIZE_DESKTOP = 70;

export const MAX_TO_SHOW_PROGRESS_BAR = 10;

export const LONG_DASH_CHAR = "&#8212;";
export const HORIZONTAL_ELLIPSIS_CHAR = "&#8230;";

export const SCROLLER_OPTIONS = {
  duration: 250,
  delay: 0,
  offset: -50,
  smooth: true,
};

export enum ENUM_CATEGORY {
  LOCALE = "locale",
  MENTAL_MODEL_TYPE = "mental_model_type",
}

export enum SURVEY_STATUSES { // Must keep syncing with @types/types.survey.d.ts
  ACTIVE = "active",
  EXPIRED = "expired",
  ARCHIVED = "archived",
}

export enum SURVEY_TYPES {
  WIDGET = "widget",
  FORM = "form",
}

/* Idea Category */
export enum IdeaCategoryDragItems {
  IDEA_CARD = "IDEA_CARD",
}

/* Idea */
export enum IdeaListDragItems {
  LIST_ITEM = "LIST_ITEM",
  GRID_ITEM = "GRID_ITEM",
}

/* Challenge */

export enum ChallengeSolutionDragItems {
  COMMENT = "COMMENT",
  SOLUTION = "SOLUTION",
}

export enum ChallengeDragItems {
  EVALUATION_METHOD_SORT_ITEM = "EVALUATION_METHOD_SORT_ITEM",
}

export enum ChallengeCommentTypes {
  IDEA = "idea",
  PRAISE = "praise",
  DISCUSSION = "discussion",
  QUESTION = "question",
}

export enum ChallengeSeriesType {
  CHALLENGE = "challenge",
  IDEA = "idea",
  PRAISE = "praise",
  DISCUSSION = "discussion",
  QUESTION = "question",
}

/* Canvas Tool */
export enum IdeaCanvasTool { // Must keep syncing with @types/types.canvas.d.ts
  ARROW = "arrow",
  TEXT = "text",
  SHAPE = "shape",
  PENCIL = "pencil",
  CONNECTION_LINE = "connectionLine",
  COLOR = "color",
  BORDER_COLOR = "borderColor",
  SIZE = "size",
  UNDO = "undo",
  COLLAPSE = "collapse",
  EXPAND = "expand",
}

export enum IdeaCanvasToolType {
  DEFAULT = "default",
  LAYOUT = "LAYOUT",
  STYLE = "style",
  HISTORY = "history",
}

export enum FabricObjectType {
  ARROW = "arrow",
  ITEXT = "i-text",
  PATH = "path",
  RECT = "rect",
  ELLIPSE = "ellipse",
  LINE = "line",
}

export enum CanvasIvDataType {
  IDEA = "idea",
  MARKER = "marker",
  MARKER_LINE = "markerLine",
  ITEXT = "itext",
  RECTANGLE = "rectangle",
  ELLIPSE = "ellipse",
  CIRCLE = "circle",
  LINE = "line",
  ARROW = "arrow",
  PATH = "path",
}

/* Idea mental model */

export enum IdeaCustomMentalModelTypes { // Must keep syncing with @types/types.ideas.d.ts
  KANBAN = "kanban",
  GRID = "grid",
  GRID_2X2 = "grid2x2",
  ORDER = "order",
  VENN = "venn",
  PINPOINT = "pinpoint",
  XY_GRAPH = "xyGraph",
}

export enum IdeaMentalModelCanvasTypes {
  CUSTOM = "custom",
}

export const WORKSPACE_CATEGORY_ID = -1;

export enum AnnotationTools {
  ARROW = "arrow",
  TEXT = "text",
  LINE = "line",
  RECTANGLE = "rectangle",
  ROUNDED_RECTANGLE = "rounded_rectangle",
  ELLIPSE = "ellipse",
  PENCIL = "pencil",
  CROP = "crop",
}

export enum AnnotationSizes {
  SMALLEST = "smallest",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  LARGEST = "largest",
}

export enum FabricTypes {
  ARROW = "arrow",
  IMAGE = "image",
  ITEXT = "i-text",
  PATH = "path",
  LINE = "line",
  RECT = "rect",
  ELLIPSE = "ellipse",
}

/* Seeded Challenges */

export const DEFAULT_SEEDED_CHALLENGE_IMAGE = IVWallpaper;

/* Evaluation Method */

export enum EvaluationMethodCategories {
  ALL = "all",
  VOTE = "vote",
  RANKING = "ranking",
  MATRIX = "matrix",
  VENN = "venn",
  RATE = "rate",
}

export enum EvaluationMethods {
  VOTE = "vote",
  RANKING_ORDER = "ranking_order",
  RANKING_QUALITY = "ranking_quality",
  RANKING_WHEN = "ranking_when",
  RANKING_COST_EFFICIENCY = "ranking_cost_efficiency",
  RANKING_IMPORTANCE = "ranking_importance",
  RANKING_ROADMAP = "ranking_roadmap",
  RANKING_INNOVATION_MATRIX = "ranking_innovation_matrix",
  MATRIX_EFFORT_VS_IMPACT = "matrix_effort_vs_impact",
  MATRIX_VALUE_VS_TIME = "matrix_value_vs_time",
  MATRIX_DIFFICULT_VS_RISK = "matrix_difficult_vs_risk",
  MATRIX_EFFORT_VS_REWARD = "matrix_effort_vs_reward",
  MATRIX_NEED_VS_WANT = "matrix_need_vs_want",
  VENN_SECTOR_RELIANCE = "venn_sector_reliance",
  VENN_LENSES_OF_INNOVATION = "venn_lenses_of_innovation",
  RATE_CONSUMER_INDUSTRIAL = "rate_consumer_industrial",
  RATE_LIKERT = "rate_likert",
  RATE_ABSTRACT_LITERAL = "rate_abstract_literal",
  RATE_FRAGMENTED_COMPLETED = "rate_fragmented_completed",
  RATE_IDEA_DEVELOPMENT_STAGE = "rate_idea_development_stage",
}

export const EvaluationMethodCategoryMap = {
  [EvaluationMethods.VOTE]: EvaluationMethodCategories.VOTE,
  [EvaluationMethods.RANKING_ORDER]: EvaluationMethodCategories.RANKING,
  [EvaluationMethods.RANKING_QUALITY]: EvaluationMethodCategories.RANKING,
  [EvaluationMethods.RANKING_WHEN]: EvaluationMethodCategories.RANKING,
  [EvaluationMethods.RANKING_COST_EFFICIENCY]:
    EvaluationMethodCategories.RANKING,
  [EvaluationMethods.RANKING_IMPORTANCE]: EvaluationMethodCategories.RANKING,
  [EvaluationMethods.RANKING_ROADMAP]: EvaluationMethodCategories.RANKING,
  [EvaluationMethods.RANKING_INNOVATION_MATRIX]:
    EvaluationMethodCategories.RANKING,
  [EvaluationMethods.MATRIX_EFFORT_VS_IMPACT]:
    EvaluationMethodCategories.MATRIX,
  [EvaluationMethods.MATRIX_VALUE_VS_TIME]: EvaluationMethodCategories.MATRIX,
  [EvaluationMethods.MATRIX_DIFFICULT_VS_RISK]:
    EvaluationMethodCategories.MATRIX,
  [EvaluationMethods.MATRIX_EFFORT_VS_REWARD]:
    EvaluationMethodCategories.MATRIX,
  [EvaluationMethods.MATRIX_NEED_VS_WANT]: EvaluationMethodCategories.MATRIX,
  [EvaluationMethods.VENN_SECTOR_RELIANCE]: EvaluationMethodCategories.VENN,
  [EvaluationMethods.VENN_LENSES_OF_INNOVATION]:
    EvaluationMethodCategories.VENN,
  [EvaluationMethods.RATE_CONSUMER_INDUSTRIAL]: EvaluationMethodCategories.RATE,
  [EvaluationMethods.RATE_LIKERT]: EvaluationMethodCategories.RATE,
  [EvaluationMethods.RATE_ABSTRACT_LITERAL]: EvaluationMethodCategories.RATE,
  [EvaluationMethods.RATE_FRAGMENTED_COMPLETED]:
    EvaluationMethodCategories.RATE,
  [EvaluationMethods.RATE_IDEA_DEVELOPMENT_STAGE]:
    EvaluationMethodCategories.RATE,
};

export const EvaluationMethodBaseProfiles = {
  [EvaluationMethods.RANKING_ORDER]: {
    options: [
      { label: i18n.t("first"), value: 1 },
      { label: i18n.t("second"), value: 2 },
      { label: i18n.t("third"), value: 3 },
    ],
  },
  [EvaluationMethods.RANKING_QUALITY]: {
    options: [
      { label: i18n.t("good"), value: 1 },
      { label: i18n.t("better"), value: 2 },
      { label: i18n.t("best"), value: 3 },
    ],
  },
  [EvaluationMethods.RANKING_WHEN]: {
    options: [
      { label: i18n.t("now"), value: 1 },
      { label: i18n.t("soon"), value: 2 },
      { label: i18n.t("later"), value: 3 },
    ],
  },
  [EvaluationMethods.RANKING_COST_EFFICIENCY]: {
    options: [
      { label: i18n.t("low"), value: 1 },
      { label: i18n.t("medium"), value: 2 },
      { label: i18n.t("high"), value: 3 },
    ],
  },
  [EvaluationMethods.RANKING_IMPORTANCE]: {
    options: [
      { label: i18n.t("good"), value: 1 },
      { label: i18n.t("better"), value: 2 },
      { label: i18n.t("best"), value: 3 },
    ],
  },
  [EvaluationMethods.RANKING_ROADMAP]: {
    options: [
      { label: i18n.t("q1"), value: 1 },
      { label: i18n.t("q2"), value: 2 },
      { label: i18n.t("q3"), value: 3 },
      { label: i18n.t("q4"), value: 4 },
    ],
  },
  [EvaluationMethods.RANKING_INNOVATION_MATRIX]: {
    options: [
      { label: i18n.t("disruptive"), value: 1 },
      { label: i18n.t("architectural"), value: 2 },
      { label: i18n.t("incremental"), value: 3 },
      { label: i18n.t("radical"), value: 4 },
    ],
  },
  [EvaluationMethods.MATRIX_EFFORT_VS_IMPACT]: {
    xLabel: i18n.t("effort"),
    yLabel: i18n.t("impact"),
  },
  [EvaluationMethods.MATRIX_NEED_VS_WANT]: {
    xLabel: i18n.t("need"),
    yLabel: i18n.t("want"),
  },
  [EvaluationMethods.MATRIX_VALUE_VS_TIME]: {
    xLabel: i18n.t("value"),
    yLabel: i18n.t("time"),
  },
  [EvaluationMethods.MATRIX_EFFORT_VS_REWARD]: {
    xLabel: i18n.t("effort"),
    yLabel: i18n.t("reward"),
  },
  [EvaluationMethods.MATRIX_DIFFICULT_VS_RISK]: {
    xLabel: i18n.t("difficult"),
    yLabel: i18n.t("risk"),
  },
  [EvaluationMethods.VENN_SECTOR_RELIANCE]: {
    options: [
      {
        label: i18n.t("technology"),
        value: 1,
      },
      {
        label: i18n.t("time"),
        value: 2,
      },
      {
        label: i18n.t("business"),
        value: 3,
      },
    ],
  },
  [EvaluationMethods.VENN_LENSES_OF_INNOVATION]: {
    options: [
      {
        label: i18n.t("desirability"),
        value: 1,
      },
      {
        label: i18n.t("feasibility"),
        value: 2,
      },
      {
        label: i18n.t("viability"),
        value: 3,
      },
    ],
  },
  [EvaluationMethods.RATE_CONSUMER_INDUSTRIAL]: {
    leftLabel: i18n.t("consumer"),
    rightLabel: i18n.t("industrial"),
  },
  [EvaluationMethods.RATE_LIKERT]: {
    leftLabel: i18n.t("disagree"),
    rightLabel: i18n.t("agree"),
  },
  [EvaluationMethods.RATE_ABSTRACT_LITERAL]: {
    leftLabel: i18n.t("time"),
    rightLabel: i18n.t("industrial"),
  },
  [EvaluationMethods.RATE_FRAGMENTED_COMPLETED]: {
    leftLabel: i18n.t("fragmented"),
    rightLabel: i18n.t("complete"),
  },
  [EvaluationMethods.RATE_IDEA_DEVELOPMENT_STAGE]: {
    leftLabel: i18n.t("developing"),
    rightLabel: i18n.t("wellformed"),
  },
};

export enum ChallengeStage {
  EXPLAIN = "explain",
  UPCOMING = "upcoming",
  IDEATE = "ideate",
  COLLABORATE = "collaborate",
  SYNTHESIS = "synthesis",
  PARTICIPANT_EVALUATION = "voting",
  MODERATOR_EVALUTION = "evaluation",
  COMPLETE = "complete",
  ARCHIVED = "archived",
}

export enum ChallengeStageDisplayNames {
  IDEATION = "Ideation",
  COLLABORATION = "Collaboration",
  SYNTHESIS = "Solution Grouping",
  EVALUATION = "Evaluation",
  COMPLETE = "Complete",
}

export enum ActionToggle {
  COLLAPSE = "collapse",
  EXPAND = "expand",
}

export enum WSEvent {
  SUBSCRIBE = "subscribe",
  UNSUBSCRIBE = "unsubscribe",
  AUTHENTICATE = "authenticate",
  AUTH_SUCCESS = "auth_success",
  AUTH_FAILED = "auth_failed",
  CHALLENGE = "challenge",
  CHALLENGE_SOLUTION = "challenge_solution",
  CHALLENGE_COMMENT = "challenge_comment",
  CHALLENGE_MESSAGE = "challenge_message",
  CHALLENGE_CREATED = "challenge_created",
  CHALLENGE_UPDATED = "challenge_updated",
  CHALLENGE_PUBLISHED = "challenge_published",
  CHALLENGE_ARCHIVED = "challenge_archived",
  CHALLENGE_UNARCHIVED = "challenge_unarchived",
  CHALLENGE_IDEATION_STARTED = "challenge_ideation_started",
  CHALLENGE_TIMING_UPDATED = "challenge_timing_updated",
  CHALLENGE_ENDED = "challenge_ended",
  CHALLENGE_EVALUATED = "challenge_evaluated",
  CHALLENGE_DELETED = "challenge_deleted",
  CHALLENGE_EXPORTED = "challenge_exported",
  CHALLENGE_SOLUTION_CREATED = "challenge_solution_created",
  CHALLENGE_SOLUTION_UPDATED = "challenge_solution_updated",
  CHALLENGE_SOLUTION_DELETED = "challenge_solution_deleted",
  CHALLENGE_COMMENT_RECEIVED = "challenge_comment_received",
  CHALLENGE_COMMENT_REPLY = "challenge_comment_reply",
  CHALLENGE_COMMENT_REPLY_INTENT_RECEIVED = "challenge_comment_reply_intent_received",
  CHALLENGE_OPEN_INTENT_RECEIVED = "challenge_open_intent_received",
  CHALLENGE_OPEN_INTENT = "challenge_open_intent",
  CHALLENGE_COMMENT_INTENT = "challenge_comment_intent",
  CHALLENGE_COMMENT_REPLY_INTENT = "challenge_comment_reply_intent",
  CHALLENGE_COMMENT_INTENT_RECEIVED = "challenge_comment_intent_received",
  CHALLENGE_COMMENT_REPLY_RECEIVED = "challenge_comment_reply_received",
  IDEA_BOARD_EXPORT_PRESENTATION_STARTED = "idea_board_export_presentation_started",
  IDEA_BOARD_EXPORT_SPREADSHEET_STARTED = "idea_board_export_spreadsheet_started",
  IDEA_BOARD_COMMENT_CREATED = "idea_board_comment_created",
  IDEA_BOARD_COMMENT_UPDATED = "idea_board_comment_updated",
  IDEA_BOARD_COMMENT_DELETED = "idea_board_comment_deleted",
  IDEA_BOARD_UPDATED = "idea_board_updated",
  IDEA_BOARD_DELETED = "idea_board_deleted",
  IDEA_BOARD_CATEGORY_CREATED = "idea_board_category_created",
  IDEA_BOARD_CATEGORY_UPDATED = "idea_board_category_updated",
  IDEA_BOARD_CATEGORY_DELETED = "idea_board_category_deleted",
  IDEA_BOARD_IDEA_CREATED = "idea_board_idea_created",
  IDEA_BOARD_IDEA_UPDATED = "idea_board_idea_updated",
  IDEA_BOARD_IDEA_DELETED = "idea_board_idea_deleted",
  IDEA_BOARD_STATUS_UPDATED = "idea_board_status_updated",
  IDEA_CANVAS = "idea_canvas",
  USER_NOTIFICATION_CREATED = "user_notification_created",
}

export enum IDEA_BOARD_EDITOR_TAB_ENUM {
  BOARD_SETTINS = "board_settings",
  PARTICIPANTS = "participants",
  EVALUATION_METHODS = "evaluation_methods",
}

export enum IDEA_BOARD_PRIVACY_ENUM {
  PRIVATE = "private",
  PUBLIC = "public",
}

export enum API_ERROR_CODE {
  IDEA_BOARD_006 = "IDEA_BOARD_006",
  IDEA_BOARD_007 = "IDEA_BOARD_007",
  CHALLENGE008 = "CHALLENGE008",
  CHALLENGE009 = "CHALLENGE009",
}

export enum USER_NOTIFICATION_TYPES {
  CHANGELOG_PUBLISHED = "changelog_published",
  CHALLENGE_INVITED = "challenge_invited",
  CHALLENGE_STAGE_STARTED = "challenge_stage_started",
  IDEA_BOARD_INVITED = "idea_board_invited",
  MEMBER_ACCEPTED = "member_accepted",
}

export enum USER_SETTINGS_KEYS {
  PROGRESS_BAR_USER_DISABLED = "progress_bar_user_disabled",
  PROGRESS_BAR_DISABLED_IDEA_BOARD_ID = "progress_bar_disabled_idea_board_id",
  PROGRESS_BAR_DISABLED_CHALLENGE_ID = "progress_bar_disabled_challenge_id",
}

export enum IDEA_SOURCE_TYPE {
  DIRECT = "direct",
  MOBILE = "mobile",
  EMAIL = "email",
  CHALLENGE_COMMENT = "challenge_comment",
  CHALLENGE_SOLUTION = "challenge_solution",
  CHROME_EXTENSION = "chrome_extension",
  SLACK = "slack",
  SURVEY = "survey",
  OPEN_WORKSPACE_API = "open_workspace_api",
  OPENAI = "openai",
}
