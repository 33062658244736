import React, { ReactElement, useState } from "react";
import { css } from "@emotion/react";
import { motion, AnimatePresence } from "framer-motion";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import { h3Bold, h4Regular, h5Bold } from "../../styles/typography";

const smallBreakPoint = pxToRem(817);
const mediumBreakPoint = pxToRem(1024);

const wrapperStyle = css`
  display: grid;
  background: ${variables.colorBackground};
  overflow: hidden;
`;

const content = css`
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: ${pxToRem(110)} ${variables.spaceBase};

  @media (min-width: ${mediumBreakPoint}) {
    max-width: ${pxToRem(1030)};
    width: 100%;
    margin: 0 auto;
    padding: ${pxToRem(110)} ${variables.spaceBase};
  }
`;

const title = css`
  ${h5Bold};
  margin-bottom: ${variables.spaceBase};
  color: ${variables.colorBrandYellow};
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${mediumBreakPoint}) {
    ${h3Bold};
  }
`;

const subTitle = css`
  max-width: ${pxToRem(640)};
  margin-bottom: ${variables.spaceMedium};
  font-size: ${pxToRem(32)};
  font-weight: ${variables.fontWeightLight};
  text-align: center;
  color: ${variables.colorMainBlue};
  line-height: 1.1;

  @media (min-width: ${mediumBreakPoint}) {
    font-size: ${pxToRem(42)};
  }
`;

const explanationText = css`
  ${h4Regular};
  margin-bottom: ${pxToRem(40)};
  line-height: 1.75;
  color: ${variables.colorSecondaryGray};
  max-width: ${pxToRem(740)};
  text-align: center;
`;

const challengeGroups = css`
  position: relative;
  display: grid;
  grid-gap: ${pxToRem(40)};

  :after {
    display: none;
    position: absolute;
    top: -${pxToRem(32)};
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 ${pxToRem(11)} ${pxToRem(11)} ${pxToRem(11)};
    border-color: transparent transparent ${variables.colorHighlightBlue}
      transparent;
    transition: left ${variables.transitionSpeed} ease;
  }

  @media (min-width: ${mediumBreakPoint}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${pxToRem(120)};

    :after {
      display: block;
    }
  }
`;

const ideateMode = css`
  :after {
    left: calc(0%);
  }
`;

const collaborateMode = css`
  :after {
    left: calc(37.5%);
  }
`;

const evaluateMode = css`
  :after {
    left: calc(75%);
  }
`;

const groupTitle = css`
  margin: ${variables.spaceBase} 0 ${variables.spaceTiny};
  font-size: ${pxToRem(24)};
  font-weight: ${variables.fontWeightSemiBold};
  color: ${variables.colorMainBlue};
  text-align: center;

  @media (min-width: ${mediumBreakPoint}) {
    margin: 0 0 ${variables.spaceTiny};
    text-align: left;
  }
`;

const selectedGroupTitle = css`
  color: ${variables.colorHighlightBlue};
`;

const groupExplanationText = css`
  ${h4Regular};
  text-align: center;
  line-height: 1.75;
  color: ${variables.colorSecondaryGray};

  @media (min-width: ${mediumBreakPoint}) {
    text-align: left;
  }
`;

const desktopIllustration = css`
  display: none;

  @media (min-width: ${mediumBreakPoint}) {
    display: block;
    position: absolute;
    max-width: 100%;
  }
`;

const mobileIllustration = css`
  width: 100%;

  img {
    max-width: 100%;
  }

  @media (min-width: ${mediumBreakPoint}) {
    display: none;
  }
`;

const separator = css`
  width: 100%;
  margin: ${pxToRem(100)} 0;
  border: 0;
  border-top: ${pxToRem(1)} solid ${variables.colorSeparator};
`;

const featureGroup = css`
  display: grid;
  grid-column-gap: ${pxToRem(80)};
  grid-row-gap: ${pxToRem(80)};
  justify-items: center;
  justify-content: center;

  @media (min-width: ${smallBreakPoint}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: ${mediumBreakPoint}) {
    grid-column-gap: ${pxToRem(120)};
  }
`;

const featureGroupItem = css`
  display: grid;
  justify-items: center;
  justify-content: center;

  @media (min-width: ${mediumBreakPoint}) {
    align-items: start;
    align-content: start;
    justify-items: start;
    justify-content: start;
  }
`;

const featureIllustration = css`
  margin-bottom: ${variables.spaceSmall};
`;

const illustrationBlock = css`
  height: ${pxToRem(50)};
  margin-bottom: ${pxToRem(20)};
`;

const featureTitle = css`
  ${h5Bold};
  font-family: ${variables.marketingHeaderFontFamily};
  margin-bottom: ${variables.spaceTiny};
  color: ${variables.colorMainBlue};
  text-transform: uppercase;
`;

const featureDescription = css`
  ${h4Regular};
  text-align: center;
  line-height: 1.75;
  color: ${variables.colorSecondaryGray};

  @media (min-width: ${mediumBreakPoint}) {
    text-align: left;
  }
`;

const illustrationSection = css`
  display: none;
  position: relative;
  width: 100%;
  max-width: ${pxToRem(1030)};
  height: ${pxToRem(560)};
  margin-bottom: ${variables.spaceLarge};

  @media (min-width: ${mediumBreakPoint}) {
    display: block;
  }
`;

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const images = [
  "https://storage.googleapis.com/iv-prod-static/ideate-illustration.webp",
  "https://storage.googleapis.com/iv-prod-static/collaborate-illustration.webp",
  "https://storage.googleapis.com/iv-prod-static/evaluate-illustration.webp",
];

const ChallengesSection = (): ReactElement => {
  const [[group, direction], setGroup] = useState([0, 0]);
  const paginateGroup = (index) => {
    if (index < group) {
      setGroup([index, -1]);
    } else if (index > group) {
      setGroup([index, 1]);
    }
  };

  return (
    <div css={wrapperStyle}>
      <div css={content}>
        <h2 css={title}>Challenges</h2>
        <h3 css={subTitle}>Generate new solutions to solve complex problems</h3>
        <p css={explanationText}>
          Challenges are built around a three-step process of ideation,
          collaboration, and evaluation that pulls together input from team
          members near and far.
        </p>
        <div css={illustrationSection}>
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              css={desktopIllustration}
              key={group}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 200 },
                opacity: { duration: 0.2 },
              }}
              src={images[group]}
              alt="App screenshot"
            />
          </AnimatePresence>
        </div>
        <div
          css={[
            challengeGroups,
            group === 0 && ideateMode,
            group === 1 && collaborateMode,
            group === 2 && evaluateMode,
          ]}
        >
          <div>
            <picture css={mobileIllustration}>
              <source
                srcSet="https://storage.googleapis.com/iv-prod-static/ideate-illustration.webp"
                type="image/webp"
              />
              <source
                srcSet="https://storage.googleapis.com/iv-prod-static/ideate-illustration.png"
                type="image/png"
              />
              <img
                src="https://storage.googleapis.com/iv-prod-static/ideate-illustration.png"
                alt="Ideate stage"
              />
            </picture>
            <button onClick={() => paginateGroup(0)}>
              <h4 css={[groupTitle, group === 0 && selectedGroupTitle]}>
                Ideate
              </h4>
              <p css={groupExplanationText}>
                Understand the problem, opportunity and constraints, then set
                your team free to independently cultivate divergent thinking.
                Ideas from others are hidden at this stage to prevent
                groupthink.
              </p>
            </button>
          </div>
          <div>
            <picture css={mobileIllustration}>
              <source
                srcSet="https://storage.googleapis.com/iv-prod-static/collaborate-illustration.webp"
                type="image/webp"
              />
              <source
                srcSet="https://storage.googleapis.com/iv-prod-static/collaborate-illustration.png"
                type="image/png"
              />
              <img
                src="https://storage.googleapis.com/iv-prod-static/collaborate-illustration.png"
                alt="Collaborate stage"
              />
            </picture>
            <button onClick={() => paginateGroup(1)}>
              <h4 css={[groupTitle, group === 1 && selectedGroupTitle]}>
                Collaborate
              </h4>
              <p css={groupExplanationText}>
                Everyone’s ideas become visible and your team is encouraged to
                ask questions, discuss ideas, give praise, and contribute
                additional thinking.
              </p>
            </button>
          </div>
          <div>
            <picture css={mobileIllustration}>
              <source
                srcSet="https://storage.googleapis.com/iv-prod-static/evaluate-illustration.webp"
                type="image/webp"
              />
              <source
                srcSet="https://storage.googleapis.com/iv-prod-static/evaluate-illustration.png"
                type="image/png"
              />
              <img
                src="https://storage.googleapis.com/iv-prod-static/evaluate-illustration.png"
                alt="Evaluate stage"
              />
            </picture>
            <button onClick={() => paginateGroup(2)}>
              <h4 css={[groupTitle, group === 2 && selectedGroupTitle]}>
                Evaluate
              </h4>
              <p css={groupExplanationText}>
                Review all contributions holistically and allow your team to
                vote for the best ideas. Moderators can reward top contributors
                and turn key takeaways into action items.
              </p>
            </button>
          </div>
        </div>
        <hr css={separator} />
        <ul css={featureGroup}>
          <div css={featureGroupItem}>
            <div css={illustrationBlock}>
              <img
                css={featureIllustration}
                src="https://storage.googleapis.com/iv-prod-static/media-illustration.svg"
                alt="Media"
              />
            </div>

            <p css={featureTitle}>Rich Media Sharing</p>
            <p css={featureDescription}>
              Include attachments, videos, embedded images and drawings to
              elaborate on ideas and enrich the conversation.
            </p>
          </div>
          <div css={featureGroupItem}>
            <div css={illustrationBlock}>
              <img
                css={featureIllustration}
                src="https://storage.googleapis.com/iv-prod-static/anonymous-illustration.svg"
                alt="Anonymous"
              />
            </div>
            <p css={featureTitle}>Anonymous Mode</p>
            <p css={featureDescription}>
              Encourage participants to freely speak their mind with the option
              to hide their identity.
            </p>
          </div>
          <div css={featureGroupItem}>
            <div css={illustrationBlock}>
              <img
                css={featureIllustration}
                src="https://storage.googleapis.com/iv-prod-static/group-illustration.svg"
                alt="Group"
              />
            </div>
            <p css={featureTitle}>Groups & Workspaces</p>
            <p css={featureDescription}>
              Segment participants across internal teams and control access for
              external contributors. Create multiple workspaces for different
              organizations.
            </p>
          </div>
          <div css={featureGroupItem}>
            <div css={illustrationBlock}>
              <img
                css={featureIllustration}
                src="https://storage.googleapis.com/iv-prod-static/timer-illustration.svg"
                alt="Hourglass"
              />
            </div>
            <p css={featureTitle}>Time-Based Challenges</p>
            <p css={featureDescription}>
              Schedule challenges and set custom time constraints for each stage
              to encourage quick ideation, collaboration, and evaluation.
            </p>
          </div>
          <div css={featureGroupItem}>
            <div css={illustrationBlock}>
              <img
                css={featureIllustration}
                src="https://storage.googleapis.com/iv-prod-static/rewards-illustration.svg"
                alt="Trophy"
              />
            </div>
            <p css={featureTitle}>Rewards</p>
            <p css={featureDescription}>
              Recognize contributors with praise and offer custom rewards to
              incentivize participation.
            </p>
          </div>
          <div css={featureGroupItem}>
            <div css={illustrationBlock}>
              <img
                css={featureIllustration}
                src="https://storage.googleapis.com/iv-prod-static/process-illustration.svg"
                alt="Process diagram"
              />
            </div>
            <p css={featureTitle}>Preset & Custom Methods</p>
            <p css={featureDescription}>
              Use proven innovation methods to frame challenges for the best
              results or customize your own challenge.
            </p>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default ChallengesSection;
