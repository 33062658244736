import React, { useState } from "react";
import getConfig from "next/config";
import { scroller } from "react-scroll";

const { publicRuntimeConfig } = getConfig();
const { canonical, appDescription } = publicRuntimeConfig;

import Head from "../components/pages/head";
import openGraphGenerator from "../helpers/open-graph-generator";
import VideoModal from "../components/common/video-modal";
import MarketingLayout from "../components/pages/marketing-layout";
import HeroSection from "../components/home/hero-section";
import ChallengesSection from "../components/home/challenges-section";
import VaultSection from "../components/home/vault-section";
import IaasSection from "../components/home/iaas-section";
import PhotoGallerySection from "../components/home/photo-gallery-section";
import ReadyToInnovate from "../components/home/ready-to-innovate";
import twitterCardGenerator from "../helpers/twitter-card-generator";
import HubspotModal from "../components/home/hubspot-modal";
import { SCROLLER_OPTIONS } from "../constants";

const openGraph = openGraphGenerator({
  title: "Invent Value",
  description: appDescription,
  type: "website",
  url: "https://www.inventvalue.com",
  image: "/static/open-graph.jpg",
  imageWidth: "1200",
  imageHeight: "1200",
});

const twitterCard = twitterCardGenerator({
  title: "Invent Value",
  description: appDescription,
  image: "/static/twitter-card.jpg",
});

const Page = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isHubSpotModalOpen, setIsHubSpotModalOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);

    setTimeout(() => {
      if (!isFormOpen) {
        window.typeformEmbed.makeWidget(
          document.getElementById("LSgq6S"),
          "https://freshconsulting.typeform.com/to/LSgq6S",
          {
            hideFooter: true,
            hideHeaders: true,
            opacity: 0,
          }
        );
      }

      scroller.scrollTo("typeform", {
        ...SCROLLER_OPTIONS,
        duration: 750,
        delay: 150,
      });
    }, 0);
  };

  return (
    <>
      <Head
        openGraph={openGraph}
        twitterCard={twitterCard}
        canonical={canonical}
        description={appDescription}
      >
        <script async src="https://embed.typeform.com/embed.js" />
      </Head>
      <MarketingLayout onScheduleDemo={() => setIsHubSpotModalOpen(true)}>
        <HeroSection
          onScheduleDemo={() => setIsHubSpotModalOpen(true)}
          onPlayVideo={() => setIsVideoModalOpen(true)}
        />
        <ChallengesSection />
        <VaultSection />
        <IaasSection />
        <PhotoGallerySection />
        <ReadyToInnovate isFormOpen={isFormOpen} onOpenForm={openForm} />
        {isVideoModalOpen && (
          <VideoModal
            isOpen={isVideoModalOpen}
            onRequestClose={() => setIsVideoModalOpen(false)}
            type="wistia"
            videoID="125x2kgvz8"
            title="Why Invent Value"
          />
        )}
        {isHubSpotModalOpen && (
          <HubspotModal
            isOpen={isHubSpotModalOpen}
            onRequestClose={() => setIsHubSpotModalOpen(false)}
          />
        )}
      </MarketingLayout>
    </>
  );
};

export default Page;
