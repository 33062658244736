import React, { ReactElement, ReactNode } from "react";
import RCTooltip from "rc-tooltip";

type Input = {
  children: ReactElement;
  placement?: "left" | "right" | "top" | "bottom";
  overlay: ReactNode | string;
};

const Tooltip = ({
  children,
  placement = "top",
  overlay,
}: Input): ReactElement => {
  const isEmptyString = typeof overlay === "string" && !overlay.trim();
  if (!overlay || isEmptyString) {
    return children;
  }

  return (
    <RCTooltip placement={placement} overlay={overlay} mouseLeaveDelay={0.01}>
      {children}
    </RCTooltip>
  );
};

export default Tooltip;
