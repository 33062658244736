import React, { ReactElement } from "react";
import { css } from "@emotion/react";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import { cta, h4Regular } from "../../styles/typography";
import ExternalLinkIcon from "../../public/static/icons/external-link.svg";

const wrapperStyle = css`
  display: grid;
  background: ${variables.colorMainBlue};
`;

const content = css`
  z-index: 1;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: ${pxToRem(80)} ${variables.spaceBase};

  @media (min-width: ${pxToRem(817)}) {
    max-width: ${pxToRem(1030)};
    width: 100%;
    margin: 0 auto;
    padding: ${pxToRem(160)} ${variables.spaceBase};
  }
`;

const learnMoreLink = css`
  ${cta};
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  grid-gap: ${variables.spaceTiny};
  min-height: ${pxToRem(40)};
  padding: ${pxToRem(12)} ${pxToRem(32)};
  border-radius: ${variables.roundness2};
  border: ${pxToRem(2)} solid ${variables.colorBrandYellow};
  color: ${variables.colorBrandYellow};
  transition: background-color ${variables.transitionSpeed} ease,
    color ${variables.transitionSpeed} ease;

  :hover {
    background: ${variables.colorMainBlue};
    color: ${variables.colorBrandYellow};
  }

  :disabled {
    opacity: ${variables.disabledOpacity};
    cursor: not-allowed;
  }
`;

const title = css`
  display: grid;
  grid-gap: ${variables.spaceBase};
  justify-items: center;
  align-items: center;
  font-size: ${pxToRem(18)};
  line-height: ${24 / 18};
  margin-bottom: ${pxToRem(60)};
  color: ${variables.colorBrandYellow};
  text-align: center;
  font-weight: ${variables.fontWeightSemiBold};

  @media (min-width: ${pxToRem(817)}) {
    grid-template-columns: auto auto auto;
  }
`;

const separator = css`
  width: ${pxToRem(116)};
  height: ${pxToRem(1)};
  background: ${variables.colorWhite};

  @media (min-width: ${pxToRem(817)}) {
    width: ${pxToRem(1)};
    height: ${pxToRem(36)};
  }
`;

const subTitle = css`
  margin-bottom: ${variables.spaceMedium};
  font-size: ${pxToRem(32)};
  font-weight: ${variables.fontWeightLight};
  text-align: center;
  color: ${variables.colorWhite};
  line-height: 1.1;

  @media (min-width: ${pxToRem(817)}) {
    margin-bottom: ${variables.spaceLarge};
    max-width: ${pxToRem(770)};
    font-size: ${pxToRem(42)};
  }
`;

const explanationText = css`
  ${h4Regular};
  margin-bottom: ${pxToRem(40)};
  line-height: 1.75;
  color: ${variables.colorSecondaryGray};
  max-width: ${pxToRem(828)};
  text-align: center;
`;

const logo = css`
  max-width: 100%;

  img {
    max-width: 100%;
  }
`;

const externalLink = css`
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
  fill: ${variables.colorBrandYellow};
`;

const IaasSection = (): ReactElement => {
  return (
    <div css={wrapperStyle}>
      <div css={content}>
        <h2 css={title}>
          <img css={logo} src="/static/fresh-logo-white.svg" alt="Fresh Logo" />
          <div css={separator} />
          <span>Innovation-as-a-Service</span>
        </h2>
        <h3 css={subTitle}>
          Move valuable ideas into market with an integrated innovation partner
        </h3>
        <p css={explanationText}>
          Generating new ideas is just the start. Fresh’s
          Innovation-as-a-Service program offers an ongoing innovation
          partnership that leverages Invent Value alongside skilled designers,
          engineers, and strategy consultants to successfully transform ideas
          into new product and service experiences that deliver results.
        </p>
        <a
          css={learnMoreLink}
          href="https://www.freshconsulting.com/innovation-as-a-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
          <ExternalLinkIcon css={externalLink} />
        </a>
      </div>
    </div>
  );
};

export default IaasSection;
