import React, { ReactElement } from "react";
import { css } from "@emotion/react";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import { h3Bold, h4Regular, h5Bold } from "../../styles/typography";

const smallBreakPoint = pxToRem(817);
const mediumBreakPoint = pxToRem(1024);

const wrapperStyle = css`
  display: grid;
  background: ${variables.colorWhite};
`;

const content = css`
  z-index: 1;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: ${pxToRem(110)} ${variables.spaceBase};

  @media (min-width: ${smallBreakPoint}) {
    max-width: ${pxToRem(1030)};
    width: 100%;
    margin: 0 auto -${pxToRem(160)};
    padding: ${pxToRem(110)} ${variables.spaceBase} 0;
  }
`;

const title = css`
  ${h5Bold};
  margin-bottom: ${variables.spaceBase};
  color: ${variables.colorBrandYellow};
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${smallBreakPoint}) {
    ${h3Bold};
  }
`;

const subTitle = css`
  max-width: ${pxToRem(640)};
  margin-bottom: ${variables.spaceMedium};
  font-size: ${pxToRem(32)};
  font-weight: ${variables.fontWeightLight};
  text-align: center;
  color: ${variables.colorMainBlue};
  line-height: 1.1;

  @media (min-width: ${smallBreakPoint}) {
    font-size: ${pxToRem(42)};
  }
`;

const explanationText = css`
  ${h4Regular};
  margin-bottom: ${pxToRem(40)};
  line-height: 1.75;
  color: ${variables.colorSecondaryGray};
  max-width: ${pxToRem(520)};
  text-align: center;
`;

const featureSection = css`
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 0 ${variables.spaceBase} ${pxToRem(110)};

  @media (min-width: ${smallBreakPoint}) {
    background: ${variables.colorBackground};
    padding: 0 ${variables.spaceBase} ${pxToRem(20)};
  }
`;

const featureSectionContent = css`
  @media (min-width: ${smallBreakPoint}) {
    max-width: ${pxToRem(1030)};
    width: 100%;
    margin: 0 auto;
    padding: ${pxToRem(200)} ${variables.spaceBase} ${pxToRem(110)};
  }
`;

const featureGroup = css`
  display: grid;
  grid-column-gap: ${pxToRem(80)};
  grid-row-gap: ${pxToRem(80)};
  justify-items: center;
  justify-content: center;

  @media (min-width: ${smallBreakPoint}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: ${mediumBreakPoint}) {
    grid-column-gap: ${pxToRem(120)};
  }
`;

const featureGroupItem = css`
  display: grid;
  justify-items: center;
  justify-content: center;

  @media (min-width: ${smallBreakPoint}) {
    align-items: start;
    align-content: start;
    justify-items: start;
    justify-content: start;
  }
`;

const illustrationBlock = css`
  height: ${pxToRem(50)};
  margin-bottom: ${pxToRem(20)};
`;

const featureIllustration = css`
  margin-bottom: ${variables.spaceSmall};
`;

const featureTitle = css`
  ${h5Bold};
  font-family: ${variables.marketingHeaderFontFamily};
  margin-bottom: ${variables.spaceTiny};
  color: ${variables.colorMainBlue};
  text-transform: uppercase;
`;

const featureDescription = css`
  ${h4Regular};
  text-align: center;
  line-height: 1.6;
  color: ${variables.colorSecondaryGray};

  @media (min-width: ${smallBreakPoint}) {
    text-align: left;
  }
`;

const vaultIllustration = css`
  max-width: 100%;

  img {
    max-width: 100%;
  }
`;

const VaultSection = (): ReactElement => {
  return (
    <div css={wrapperStyle}>
      <div css={content}>
        <h2 css={title}>Vault</h2>
        <h3 css={subTitle}>
          Easily capture and organize ideas across your organization
        </h3>
        <p css={explanationText}>
          Vault is a central repository for ideas generated through innovation
          challenges or submitted independently by team members.
        </p>
        <picture css={vaultIllustration}>
          <source
            srcSet="https://storage.googleapis.com/iv-prod-static/vault-illustration.webp"
            type="image/webp"
          />
          <source
            srcSet="https://storage.googleapis.com/iv-prod-static/vault-illustration.png"
            type="image/png"
          />
          <img
            src="https://storage.googleapis.com/iv-prod-static/vault-illustration.png"
            alt="Vault Screenshot"
          />
        </picture>
      </div>
      <div css={featureSection}>
        <div css={featureSectionContent}>
          <ul css={featureGroup}>
            <div css={featureGroupItem}>
              <div css={illustrationBlock}>
                <img
                  css={featureIllustration}
                  src="https://storage.googleapis.com/iv-prod-static/masonry-illustration.svg"
                  alt="Masonry View"
                />
              </div>
              <p css={featureTitle}>Masonry View</p>
              <p css={featureDescription}>
                Visually browse through ideas to get a pulse on the latest
                thinking across departments in your organization.
              </p>
            </div>
            <div css={featureGroupItem}>
              <div css={illustrationBlock}>
                <img
                  css={featureIllustration}
                  src="https://storage.googleapis.com/iv-prod-static/upvote-illustration.svg"
                  alt="Thumb-up"
                />
              </div>
              <p css={featureTitle}>Upvoting & Collaboration</p>
              <p css={featureDescription}>
                Continually upvote ideas to track interest and collaborate to
                increase team engagement without time constraints.
              </p>
            </div>
            <div css={featureGroupItem}>
              <div css={illustrationBlock}>
                <img
                  css={featureIllustration}
                  src="https://storage.googleapis.com/iv-prod-static/quick-idea-illustration.svg"
                  alt="Brain"
                />
              </div>
              <p css={featureTitle}>Quick Idea Capture</p>
              <p css={featureDescription}>
                Enable team members to quickly capture ideas on the fly and save
                them to a central place for further development.
              </p>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VaultSection;
