// http://ogp.me/
// https://developers.facebook.com/docs/sharing/opencard/object-properties/
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const { twitterHandle } = publicRuntimeConfig;

type Props = {
  title: string;
  description: string;
  image: string;
};

type ComponentOutput = {
  name: string;
  content: string;
}[];

const twitterCardGenerator = ({
  title,
  description,
  image,
}: Props): ComponentOutput => {
  const card: ComponentOutput = [];
  const missingRequiredProps: string[] = [];
  const missingRecommendedProps: string[] = [];

  card.push({ name: "twitter:card", content: "summary_large_image" });
  card.push({ name: "twitter:site", content: twitterHandle });
  card.push({ name: "twitter:creator", content: twitterHandle });

  if (title) {
    card.push({ name: "twitter:title", content: title });
  } else {
    missingRequiredProps.push("title");
  }

  if (description) {
    card.push({
      name: "twitter:description",
      content: description.substring(0, 300),
    });
  } else {
    missingRecommendedProps.push("description");
  }

  if (image) {
    card.push({ name: "twitter:image", content: image });
  } else {
    missingRecommendedProps.push("image");
  }

  if (missingRequiredProps.length > 0) {
    console.error("Missing required Open Graph props:", missingRequiredProps);
  }

  if (missingRecommendedProps.length > 0) {
    console.warn(
      "Missing recommended Open Graph props:",
      missingRecommendedProps
    );
  }

  return card;
};

export default twitterCardGenerator;
