// Refer: https://github.com/zeit/next.js/blob/canary/examples/with-google-analytics/lib/gtag.js
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const { gaTrackingId } = publicRuntimeConfig;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const gtagPageview = ({
  path,
  title,
}: {
  path: string;
  title: string;
}): void => {
  if (window.gtag) {
    window.gtag("config", gaTrackingId, {
      page_path: path,
      page_title: title,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const gtagSendEvent = ({
  action,
  property,
}: {
  action: string;
  property: string;
}): void => {
  if (window.gtag) {
    window.gtag("event", action, property);
  }
};

export { gtagPageview, gtagSendEvent };
