// http://ogp.me/
// https://developers.facebook.com/docs/sharing/opengraph/object-properties/

type Props = {
  title: string;
  description: string;
  type: string;
  url: string;
  image: string;
  imageWidth: string;
  imageHeight: string;
};

type ComponentOutput = {
  property: string;
  content: string;
}[];

const openGraphGenerator = ({
  title,
  description,
  type,
  url,
  image,
  imageWidth,
  imageHeight,
}: Props): ComponentOutput => {
  const graph: ComponentOutput = [];
  const missingRequiredProps: string[] = [];
  const missingRecommendedProps: string[] = [];

  if (title) {
    graph.push({ property: "og:title", content: title });
  } else {
    missingRequiredProps.push("title");
  }

  if (description) {
    graph.push({
      property: "og:description",
      content: description.substring(0, 300),
    });
  } else {
    missingRequiredProps.push("description");
  }

  if (type) {
    graph.push({ property: "og:type", content: type });
  } else {
    missingRequiredProps.push("type");
  }

  if (type) {
    graph.push({ property: "og:url", content: url });
  } else {
    missingRequiredProps.push("url");
  }

  if (image) {
    graph.push({ property: "og:image", content: image });
  } else {
    missingRecommendedProps.push("image");
  }

  if (imageWidth) {
    graph.push({ property: "og:image:width", content: imageWidth });
  } else {
    missingRecommendedProps.push("imageWidth");
  }

  if (imageHeight) {
    graph.push({ property: "og:image:height", content: imageHeight });
  } else {
    missingRecommendedProps.push("imageHeight");
  }

  if (missingRequiredProps.length > 0) {
    console.error("Missing required Open Graph props:", missingRequiredProps);
  }

  if (missingRecommendedProps.length > 0) {
    console.warn(
      "Missing recommended Open Graph props:",
      missingRecommendedProps
    );
  }

  return graph;
};

export default openGraphGenerator;
